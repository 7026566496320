import { SVGProps, Ref, forwardRef } from 'react'

const SvgPowerOfAttorney = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.426.113A1.5 1.5 0 0 1 6 0h4.5a1.5 1.5 0 0 1 1.5 1.5h9.75A2.25 2.25 0 0 1 24 3.749v18a2.25 2.25 0 0 1-2.25 2.25H2.25A2.25 2.25 0 0 1 0 21.749v-18a2.25 2.25 0 0 1 2.25-2.25H4.5L5.427.113ZM21.75 3H12v8.25a.75.75 0 0 1-1.2.6L8.25 9.937 5.7 11.85a.75.75 0 0 1-1.2-.6V2.999H2.25a.75.75 0 0 0-.75.75v18a.75.75 0 0 0 .75.75h19.5a.75.75 0 0 0 .75-.75v-18a.75.75 0 0 0-.75-.75ZM6 9.75V1.499h4.5v8.25L8.7 8.4a.75.75 0 0 0-.9.001L6 9.75Zm-.75 8.249a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H5.25Zm-.75-3.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Zm9.75-5.25a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgPowerOfAttorney)
export default ForwardRef
